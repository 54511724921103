import { useState, useEffect } from "react"
import shuffle from "lodash.shuffle"

import { get } from "lib/redirects"
import { useLanguages } from "lib/languages"
import { useTranslation } from "lib/i18n/translation"
import AcquisitionQuery from "components/common/acquisitions"
import { Product } from "components/common"
import { baseProduct } from "components/common/skeleton"
import SkeletonLink from "components/common/skeleton-link"
import Products from "components/common/products"
import { Slider } from "components/common"

const Acquisitions = ({}) => {
	const { c } = useTranslation("common")
	const [products, setProducts] = useState(null)
	const [update, setUpdate] = useState(false)
	const { current } = useLanguages()

	useEffect(() => {
		setProducts(null)
		setUpdate(true)
	}, [current])

	return (
		<section>
			<h2 className="section-title icon-reel-new">{c("newly-available-films-strong")}</h2>
			<AcquisitionQuery
				home={true}
				renderLoading={() => (
					<>
						<p className="excerpt skeleton">lorem ipsum dolor</p>
						<LocalSlider products={[baseProduct]} loading />
					</>
				)}
			>
				{acquisitions => {
						return (
							<>
								<p className="excerpt">{acquisitions.descriptionShort}</p>
								<Products products={acquisitions.products.items}>
									{(prts, loading) => {
										let displayedAcquisitions = products

										if (!displayedAcquisitions || update) {
											if (!loading || (!!displayedAcquisitions && update)) {
												displayedAcquisitions = shuffle(prts)
												setProducts(displayedAcquisitions)
												setUpdate(false)
											} else {
												displayedAcquisitions = prts
											}
										}
										if (displayedAcquisitions.length === 0 || displayedAcquisitions[0].id === 9999999) {
											displayedAcquisitions = shuffle(prts)
										}

										return (
											<LocalSlider products={displayedAcquisitions} loading={loading} />
										)
									}}
								</Products>
							</>
						)
				}}
			</AcquisitionQuery>
			<p className="centered">
				<SkeletonLink href={`${get("/news")}#acquisitions`}>
					<a title={c("see-newly-available-films")} className="icon-reel button">{c("see-newly-available-films")}</a>
				</SkeletonLink>
			</p>
		</section>
	)
}

const LocalSlider = ({ products, loading }) => (
	<Slider
		slides={products}
		slideComponent={Product}
		slidePropKey="product"
		loading={loading}
		asList={false}
	/>
)

export default Acquisitions
