import React from "react"
import SkeletonLink from "components/common/skeleton-link"
import fullname from "lib/tools/name"
import { get, replace } from "lib/redirects"

export default ({ list, monthListId }) => {

	const target = "/lists/[id]"
	const realTarget = get(target)

	return (
		<div className="slide-content">
			<ul className="grid list directors">

				{list.map((category, index) => (
					<li
						key={index}
						className={`item${monthListId == category.id ? " featured" : ""}`}
					>
						<SkeletonLink href={target} as={replace(realTarget, category.linkRewrite)}>
							<a title={category.name} className="title-alt">
								{fullname(category, { multiline: true})}
							</a>
						</SkeletonLink>
					</li>
				))}
			</ul>
		</div>
	)
}
