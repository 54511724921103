import { useState, useRef, useEffect } from "react"
import Item from "./item"

const MAX_WAIT_FOR = 10

const CoilSlider = ({ coils }) => {
	const [current, setCurrent] = useState(coils[0])
	const [next, setNext] = useState(coils[1])
	const [shownProducts, setShownProducts] = useState([
		{
			id: coils[0].product.id,
			recommenders: coils[0].recommender ? [coils[0].recommender.recommender] : [],
			disabled: true,
			count: 1
		},
		{
			id: coils[1].product.id,
			recommenders: coils[1].recommender ? [coils[1].recommender.recommender] : [],
			disabled: true,
			count: 1
		}
	])
	const [shownRecommenders, setShownRecommenders] = useState([
		coils[0].recommender ? coils[0].recommender.recommender : null,
		coils[1].recommender ? coils[1].recommender.recommender : null
	])
	const [nexting, setNexting] = useState(false)
	const [height, setHeight] = useState(null)
	const firstRef = useRef(null)

	const handleNext = () => {
		setNexting(true)
		setTimeout(() => {
			setCurrent(next)
			randomize()
		}, 1000)
	}

	const randomize = () => {
		const availableCoils = coils
			.filter(c => !shownProducts.filter(sp => sp.disabled).map(p => p.id).includes(c.product.id))
			.filter(c => c.product.recommendersIds.filter(id => !shownRecommenders.includes(id)).length > 0)

		const newNext = availableCoils[Math.floor(Math.random() * availableCoils.length)]
		const nextShown = shownProducts.find(sp => sp.id == newNext.product.id)

		let recommendersIds = newNext.product.recommendersIds
		if (nextShown) {
			recommendersIds = recommendersIds
				.filter(id => !nextShown.recommenders.includes(id))
		}
		recommendersIds = recommendersIds
			.filter(id => !shownRecommenders.includes(id))

		let recommender = randomRecommender({ recommendersIds }, next.recommender.sentence)

		if (!recommender) return randomize()

		setShownRecommenders(old => {
			old.push(recommender.recommender)
			if (old.length > 10) {
				old.shift()
			}
			return old
		})
		if (!recommender) recommender = randomRecommender(newNext.product)
		setNext({
			...newNext,
			recommender
		})
		setShownProducts(old => {
			let shown = old.find(sp => sp.id == newNext.product.id)
			if (!shown) {
				shown = { id: newNext.product.id, recommenders: newNext.product.recommendersIds.length == 1 ? [] : [recommender.recommender], count: 0, disabled: true }
				old.push(shown)
			} else {
				shown.recommenders = [...shown.recommenders, recommender.recommender]
				if (shown.recommenders.length == newNext.product.recommendersIds.length) {
					shown.recommenders = []
				}
				shown.count = 0
				shown.disabled = true
				old[old.map(sp => sp.id).indexOf(newNext.product.id)] = shown
			}
			return old.map(sp => ({ ...sp, count: sp.disabled ? sp.count + 1 : sp.count, disabled: coils.length > 10 ? sp.count <= MAX_WAIT_FOR : false }))
		})
		setNexting(false)
	}

	const manageHeight = () => {
		if (firstRef.current) {
			setHeight(firstRef.current.offsetHeight - 0.5)
		}
	}

	useEffect(() => {
		manageHeight()
	}, [firstRef.current])

	useEffect(() => {
		window.addEventListener('resize', manageHeight)
		return () => {
			window.removeEventListener('resize', manageHeight)
		}
	}, [])

	return (
		<div style={height ? { height: `${height}px`, overflow: "hidden", marginBottom: "2rem" } : {}} className={nexting ? "trans-coil" : ""}>
			<Item
				key={`coils-current-${current.product.id}`}
				product={current.product}
				recommender={current.recommender}
				active={!nexting}
				nexting={nexting}
				top={`0px`}
				firstRef={firstRef}
				onNext={handleNext}
			/>
			<Item
				key={`coils-next-${next.product.id}`}
				product={next.product}
				recommender={next.recommender}
				active={nexting}
				nexting={nexting}
				isNext={true}
				top={`calc(${height * -1}px - var(--gutter-gap))`}
				onNext={handleNext}
			/>
		</div>
	)
}

const randomRecommender = (product, lastSentence) => {
	let sentenceIndex = Math.floor(Math.random() * Math.floor(3)) + 1
	let listSentence = `coil-sentence-${sentenceIndex}`
	while (listSentence == lastSentence) {
		sentenceIndex = Math.floor(Math.random() * Math.floor(3)) + 1
		listSentence = `coil-sentence-${sentenceIndex}`
	}
	const recommenderIndex = Math.floor(Math.random() * (product.recommendersIds.length - 1))
	if (product.recommendersIds.length == 0) return null
	let recommender = product.recommendersIds[recommenderIndex]
	return {
		sentence: listSentence,
		recommender
	}
}

export default CoilSlider
