import { Query } from "components/common"
import { FETCH_MONTH_LIST } from "lib/graphql"
import { useMainCategories } from "lib/tools/categories"
import Products from "components/common/products"
import { useCountry } from "lib/country"

export default ({renderLoading, children}) => {
	const { allLists } = useMainCategories()
	const { country } = useCountry()

	return (
		<Query
			query={FETCH_MONTH_LIST}
			renderLoading={renderLoading}
			options={{
				fetchPolicy: "network-only",
				context: {
					headers: {
						"x-forwarded-for": country.ip
					}
				}
			}}
		>
			{({cms: { topMenuWidget: { elements: { items }}}}) => (
				<Products
					products={items.filter(i => i.targetType == "PRODUCT").map(p => p.target)}
				>
					{products => {
						let list = items.filter(i => i.targetType == "CATEGORY")
						if (list.length > 0) {
							list = allLists.find(l => l.id == list[0].target.id)
						} else {
							list = null
						}
						return children(list, products)
					}}
				</Products>
			)}
		</Query>
	)
}
