import { useTranslation } from "lib/i18n/translation"
import SkeletonLink from "components/common/skeleton-link"
import { Product } from "components/common"
import { baseSelection } from "components/common/skeleton"
import MonthListQuery from "components/common/month-list"

const MonthList = ({}) => {
	return (
		<MonthListQuery
			renderLoading={() => <Content category={baseSelection} products={baseSelection.products.items} loading={true} />}
		>
			{ (category, products) => {
					if (!category) return null
					return <Content category={category} products={products} />
			}}
		</MonthListQuery>
	)
}

const Content = ({category: {name, linkRewrite}, products, loading}) => {

	const { c, ec } = useTranslation("common")

	return (
		<section className={loading ? "skel-loading" : ""}>
			<h2 className="section-title icon-chair">{c("list-of-the-month-strong")}<SkeletonLink href="/lists/[id]" as={`/lists/${linkRewrite}`}><a title={name} className="list-link">{name}</a></SkeletonLink></h2>
			<ul className="reels list">
				{ products.slice(0, 3).map((product, index) => (
					<li key={`home-month-list-${index}`}>
						<Product product={product} minified={index > 0} loading={loading} />
					</li>
				))}
			</ul>
			<p className="centered">
				<SkeletonLink href="/lists/[id]" as={`/lists/${linkRewrite}`}>
					<a title={c("see-complete-list")} className="icon-chair button">{ec('see_complete_list')}</a>
				</SkeletonLink>
			</p>
		</section>
	)
}

export default MonthList
