import { Query } from "components/common"

import { FETCH_ACQUISITIONS, FETCH_ALL_ACQUISITIONS } from "lib/graphql"
import { HOME_ACQUISITIONS, ACQUISITIONS } from "lib/tools/categories-list"
import { useCountry } from "lib/country"

const AcquisitionQuery = ({ renderLoading, children, all, home }) => {
	const { country } = useCountry()
	const id = home
		? process.env.CATEGORIES.find(c => c.key === HOME_ACQUISITIONS).id
		: process.env.CATEGORIES.find(c => c.key === ACQUISITIONS).id

	return (
		<Query
			query={all ? FETCH_ALL_ACQUISITIONS : FETCH_ACQUISITIONS}
			options={{variables: {countryQuery: `parent: ${id} AND name.value:*-${country.isoCode}`}}}
			renderLoading={renderLoading}
		>
			{({cms: { categories: { items: [category] }}}) => {

				if (!category) {
					return null
				}

				if (all) {
					const categories = category.children.items.filter(c => !c.name.includes("acquisitions"))

					return children({
						... category,
						children: { items: categories.map(year => ({
							...year,
							children: { items: year.children.items.map(month => ({
								...month,
								products: { items: month.products.items }
							}))}
						}))},
						products: []
					})
				} else {
					return children({
						...category,
						products: { items: category.products.items }
					})
				}
			}}
		</Query>
	)
}

export default AcquisitionQuery
