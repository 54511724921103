export default (list, options) => {
	if (!options) options = { multiline: false }
	let fullname
	if (list.inverted) {
		fullname = (
			<>
				<strong>{list.lastname}</strong>{options.multiline ? <br /> : " "}{list.firstname}
			</>
		)
	} else {
		fullname = (
			<>
				{list.firstname}{options.multiline ? <br /> : " "}<strong>{list.lastname}</strong>
			</>
		)
	}
	return fullname
}
