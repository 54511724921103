import Slider from "./coil/slider"
import { useEffect, useState } from "react"
import { useLanguages } from "lib/languages"
import { useCountry } from "lib/country"
import { getFilm } from "lib/film-fetcher"

const Coil = ({ randomProducts }) => {
	const { current } = useLanguages()
	const { country } = useCountry()

	const [coilProducts, setCoilProducts] = useState(randomProducts)

	useEffect(() => {
		async function updateCoilProducts() {
			const updatedRandomProducts = await Promise.all(randomProducts.map(async product => {
				product.product = await getFilm(product.product.id, current, country)

				return product
			}))

			setCoilProducts(updatedRandomProducts)
		}

		updateCoilProducts()
	}, [current])

	if (coilProducts.length < 10) {
		return (
			<p>
				<br />
				<i>Pas assez de films dans le bobinez (10 films minimum)</i>
			</p>
		)
	}

	return (
		<Slider coils={coilProducts} />
	)
}

export default Coil
