import { useTranslation } from "lib/i18n/translation"
import { TREASURES } from "lib/tools/categories"

import { Category } from "components/common"
import SkeletonLink from "components/common/skeleton-link"
import Image from "components/common/image"
import { Slider } from "components/common"

import image, { CATEGORY_LOGO_LARGE, CATEGORY_TREASURE } from "lacinetek-common/src/images"

const Treasures = ({}) => {

	const { c } = useTranslation("common")

	const baseTreasure = { name: "", banner: '/static/images/default_treasure.png', products: { items: [] } }

	return (
		<section>
			<h2 className="section-title icon-eye">{c("treasures-strong")}</h2>
			<Category
				category={TREASURES}
				light={true}
				renderLoading={() => (
					<TreasuresSlider treasures={[baseTreasure, baseTreasure, baseTreasure, baseTreasure]} />
				)}
			>
				{({ children: { items: treasures }}) => (
					<TreasuresSlider key="treasures-list" treasures={treasures} />
				)}
			</Category>
		</section>
	)
}

const Slide = ({ loading, index, ...treasure }) => {
	const { c } = useTranslation("common")

	return (
		<SkeletonLink
			href={`/treasures/[id]`}
			as={`/treasures/${c(treasure.linkRewrite)}`}
			loading={loading}
		>
			<a
				title={`${c("treasures")} / ${treasure.name}`}
				data-overlay="#overlay-institution"
				className="slide-content"
			>
				<div className="infos">
					<figure className={loading ? "skeleton" : ""}>
						<picture>
							<Image
								src={image(treasure, CATEGORY_LOGO_LARGE)}
								alt={treasure.name}
							/>
						</picture>
					</figure>
				</div>
				<figure className={`slide-bg${loading ? " skeleton" : ""}`}>
					<picture>
						<Image
							src={loading || image(treasure, CATEGORY_TREASURE)}
							alt={treasure.name}
						/>
					</picture>
				</figure>
			</a>
		</SkeletonLink>
	)
}

const TreasuresSlider = ({treasures, loading}) => (
	<Slider
		slides={treasures}
		slideComponent={Slide}
		loading={loading}
		className="min institutions"
		hidePagination
	/>
)

export default Treasures
