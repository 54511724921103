import getConfig from "next/config"

import image, { CATEGORY_BANNER_BIG } from "lacinetek-common/src/images"
import { FEATURE_HIDE_IN_PRODUCTION } from "lacinetek-common/src/metadata"

import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"
import { FETCH_SELECTIONS } from "lib/graphql/queries"

import { Query } from "components/common"
import SkeletonLink from "components/common/skeleton-link"
import { Slider } from "components/common"
import Image from "components/common/image"

const { publicRuntimeConfig } = getConfig()

const Selections = ({ title, hideDesc, current }) => {
	const { c } = useTranslation("common")
	const { country } = useCountry()
	const baseSelection = { name: "", banner: "/static/images/default_selection.jpg" }

	return (
		<section>
			<h2 className="section-title icon-projector">{title ? c("focus-title") : c("focus-single")}</h2>
			<Query
				query={FETCH_SELECTIONS}
				renderLoading={() => (
					<Content parent={{ descriptionShort: "Lorem ipsum dolor sit amet consectur est." }} selections={[baseSelection, baseSelection, baseSelection, baseSelection]} loading={true} hideDesc={hideDesc} />
				)}
				options={{
					fetchPolicy: "network-only",
					context: {
						headers: {
							"x-forwarded-for": country.ip
						}
					}
				}}
			>
				{({ cms: { carouselWidget: { slides: { items } } } }) => {
					let selections = items
						.filter(i => (i.target !== null && (!current || current.id !== i.target.id)))
						.filter(i => {
							if (publicRuntimeConfig.lacinetekEnv !== "production") {
								return true
							}

							return i.target.metadata
								&& i.target.metadata.findIndex(m => m.name === FEATURE_HIDE_IN_PRODUCTION && m.value === "oui") === -1
						})
						.map(i => i.target)

					return (
						<Content key="selections-list" selections={selections} hideDesc={hideDesc} />
					)
				}}
			</Query>
		</section>
	)
}

const Slide = ({ loading, index, ...selection }) => {
	const { c } = useTranslation("common")

	return (
		<SkeletonLink
			href="/selection/[id]"
			as={`/selection/${selection.linkRewrite}`}
			loading={loading}
			className="slide-content"
		>
			<a
				title={c("see-selection")}
				data-overlay="#overlay-discover"
				className={`slide-content${loading ? " skeleton" : ""}`}
			>
				<div className={`infos${loading ? " skeleton" : ""}`}>
					<h3>{c(selection.name)}</h3>
				</div>
				<figure>
					<picture>
						<Image
							src={image(selection, CATEGORY_BANNER_BIG)}
							className={loading ? "skeleton" : ""}
							alt={selection.name}
						/>
					</picture>
				</figure>
			</a>
		</SkeletonLink>
	)
}

const Content = ({ selections, loading }) => (
	<Slider
		slides={selections}
		slideComponent={Slide}
		loading={loading}
		className="min"
		hidePagination
	/>
)

export default Selections
