import { useEffect, useState } from "react"
import Router from "next/router"
import moment from "moment"
import { getDirectors } from "lacinetek-common/src/models/product"

import { i18n } from "lib/i18n"
import { useAuth } from "lib/auth"
import { isBrowser } from "lib/tools"

import push from "./data-layer"

const debug = require("debug")("lacinetek:gtm")

const queue = []

const Gtm = ({
	pageType = "Type page non défini",
	pagePath = "Path page non défini",
	pageTitle = "Title page non défini",
	product,
	search,
	resultsNumber,
	list,
	cart,
	order,
	step
}) => {
	const { user } = useAuth()
	const [userId, setUserId] = useState()

	const dataLayer = (dataLayer) => {
		if (initialized) {
			push(dataLayer)
			return
		}

		queue.push(dataLayer)
		return
	}

	const pageLayer = () => {
		const push = {
			// TODO page realisateur
			// TODO autre page
			event: "page",

			page: {
				pageType,
				pagePath,
				pageTitle,
				language: i18n.language.toUpperCase()
			}
		}

		if (product) {
			productPage(push, product)
		}

		if (list) {
			listPage(push, list)
		}

		if (search) {
			searchPage(push, search, resultsNumber)
		}

		if (page404) {
			page404(push, page404)
		}

		dataLayer(push)
	}

	const productPage = (push, product) => {
		push.page.filmName = product.name
		push.page.directorName = product.director
		push.page.filmId = product.id
		push.page.filmCountry = product.origin
		push.page.filmYear = product.year
		push.page.filmLength = product.duration
		push.page.filmGenre = product.genre
	}

	const listPage = (push, list) => {
		push.page.directorName = list.name
	}

	const searchPage = (push, search, resultsNumber) => {
		push.page.searchKeywords = search
		push.page.searchResultsNumber = resultsNumber
	}

	const page404 = (push, page404) => {
		push.page.page404 = page404
	}

	const ecommerceLayer = () => {
		if (!product) {
			return
		}

		dataLayer({
			event: "ecommerce",
			eventCommand: "productDetail",
			currencyCode: "EUR",
			productList: [
				{
					productName: product.name,
					productId: product.id,
					productCategory1: "",
					filmName: product.name,
					directorName: product.director
				}
			]
		})
	}

	const cartLayer = () => {
		if (!cart || step === 3 ) {
			return
		}

		const steps = {
			1: "shoppingBag",
			2: "paiement",
		}

		dataLayer({
			event: "ecommerce",
			eventCommand: "checkoutStep",
			currencyCode: "EUR",
			checkoutStepNumber: step,
			checkoutStepName: steps[step],
			productList: cart.content.items.map(({ accessMode, product, gift }) => {
				return {
					productName: product.name,
					productId: product.id,
					productCategory1: accessMode.type ? `${accessMode.type} ${accessMode.quality}` : null,
					productIsGift : !!gift ? "Yes" : "No",
					productUnitPrice: accessMode.price,
					productQuantity : 1,
					filmName : product.name,
					directorName : (product.extension && product.extension.directors) ? getDirectors(product).map(({director}) => director.name).join(" & ") : null
				}
			})
		})
	}

	const confirmLayer = () => {
		if (!cart || step !== 3) {
			return
		}

		dataLayer({
			event: "ecommerce",
			eventCommand: "orderConfirmation",
			currencyCode: "EUR",
			orderId: `${order.id}`,
			orderRevenue: order.cart.totalPrice,
			orderTax: order.cart.totalPrice - order.cart.totalPriceWithouTax,
			orderPaymentMode: order.paymentGateway,
			codePromo: order.cart.vouchers[0] ? order.cart.vouchers[0].name : null,
			conversionType: order.cart.content.items[0].product.type,
			productList: order.cart.content.items.map(({ accessMode, product, gift }) => {
				return {
					productName: product.name,
					productId: product.id,
					productCategory1: accessMode.type ? `${accessMode.type} ${accessMode.quality}` : null,
					productIsGift : !!gift ? "Yes" : "No",
					productUnitPrice: accessMode.price,
					productQuantity : 1,
					filmName : product.name,
					directorName : (product.extension && product.extension.directors) ? getDirectors(product).map(({director}) => director.name).join(" & ") : null//product.extension.directors ? product.extension.directors.items.find(item => Array.isArray(item.roles) && item.roles.includes("Réalisateur")).director.name : null
				}
			})
		})
	}

	const unloggedLayer = (userId) => {
		dataLayer({
			event: "user",
			user: {
				userLoginStatus: "unlogged",
				userId,
			}
		})
	}

	const loggedLayer = (user) => {
		dataLayer({
			event: "user",
			user: {
				userLoginStatus: "logged",
				userId: user.profile.id,
				userEmail: user.profile.email,
				firstName: user.profile.firstname,
				lastName: user.profile.lastname,
				accountCreationDate: moment(user.profile.dateAdd).format("YYYY/MM/DD"),
				birthDate: moment(user.profile.birthdate).format("YYYY/MM/DD"),
				// TODO firstOrderDate and lastOrderDate
				// TODO userSubscriptionStatus, userSubscriptionType and unsubscriptionDate
			}
		})
	}

	useEffect(() => {
		pageLayer()
		ecommerceLayer()
		cartLayer()
		confirmLayer()
	}, [])

	useEffect(() => {
		// Detect when user logout
		if (!user && userId) {
			unloggedLayer(userId)
			setUserId()
			return
		}

		if (userId || !user) {
			return
		}

		// Detect when user login or refresh page
		setUserId(user.profile.id)
		loggedLayer(user)
	}, [user])

	return null
}

export default Gtm

let initialized = false
export function init() {
	if (!isBrowser) {
		return
	}

	debug("Initialize GTM")

	const gtmJs = document.createElement("script")
	gtmJs.id = "gtm-js"
	gtmJs.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_ID}`

	const initScript = document.createElement("script")
	initScript.id = "gtm-init"
	initScript.type = "text/javascript"
	initScript.innerHTML = `
		(function(w,d,s,l,i){
			w[l]=w[l]||[];
			w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
			var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
			j.async=true;
			j.src='https://data.lacinetek.com/djcwkhzo.js?id='+i+dl;
			f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${process.env.GTM_ID}');
	`

	document.body.appendChild(gtmJs)
	document.body.appendChild(initScript)

	if (process.env.NODE_ENV === "development") {
		Router.events.off("routeChangeComplete", onRouteChangeComplete)
	}

	initialized = true
	Router.events.on("routeChangeComplete", onRouteChangeComplete)

	queue.forEach(dataLayer => {
		push(dataLayer)
	})
	queue.length = 0
}

function onRouteChangeComplete(url) {
	if (typeof window === "undefined" || typeof window.dataLayer === "undefined") {
		return
	}

	debug("Change route to", url, window.location.pathname + window.location.search)
	window.dataLayer.push({
		"event": "route_changed",
		"page": window.location.pathname + window.location.search
	})
}
