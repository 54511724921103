import classNames from "classnames"
import image, {
	PRODUCT_BOBINEZ,
	PRODUCT_COVER
} from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import classification from "lib/tools/classification"

import Image from "components/common/image"
import ProductAccessLink from "components/common/product-access-link"
import SkeletonLink from "components/common/skeleton-link"
import { ProductRecommender } from "components/common"
import { SubscriptionAvailability } from "components/film/subscription-availability"


export default ({ product, recommender, active, loading, top, onNext, firstRef, isNext, nexting }) => {
	const { c } = useTranslation("common")

	return (
		<section
			ref={firstRef}
			className={classNames(
				"focus front coil-item",
				{ "next-item": active && isNext },
				{ "active": active },
				{ "skeleton-container": loading }
			)}
			style={{ top }}
		>
			<div className="infos">
				<SkeletonLink
					href="/film/[id]"
					as={`/film/${product.linkRewrite}`}
					key={`product-link-${product.id}`}
				>
					<a title={c("single-single-film")} className={loading ? "skeleton" : ""}>
						<span className="title">
							{product.name}
							{classification(product) && (
								<>
									<div style={{ display: "inline-block", width: "10px" }} />
									{classification(product)}
								</>
							)}
						</span>
						<span className="director">{c("by")} {product.director}</span>
					</a>
				</SkeletonLink>
				<ProductRecommender
					key={`recommender-${product.id}`}
					product={product}
					recommender={recommender}
					type="in-list-of"
					loading={loading}
				/>
			</div>
			{
				(!loading && product.inMonthSelection)
				&& <SkeletonLink href="/selection">
					<a
						title={c("month-selection")}
						className="film-event icon-selection"
					>
						{c("month-selection")}
					</a>
				</SkeletonLink>
			}
			<SubscriptionAvailability product={product} loading={nexting} />
			<nav className="focus-nav">
				<ProductAccessLink product={product} alt={true} onlyShowOrBuy={true} />
				<a
					href="#"
					onClick={e => { e.preventDefault(); onNext() }}
					rel="nofollow noopener"
					title={c("scroll")}
					className={classNames(
						"detonate",
						{ "skeleton": loading },
						{ "nexting": active }
					)}
				>
					{c("scroll")}
				</a>
			</nav>
			<figure className={`background ${loading ? "skeleton" : ""}`}>
				<picture>
					<Image
						src={image(product, PRODUCT_BOBINEZ, PRODUCT_COVER)}
						alt={product.name}
					/>
				</picture>
			</figure>
		</section>
	)
}
