import { useEffect, useState, useRef } from "react"
import { useTranslation } from "lib/i18n/translation"
import { Slider } from "components/common"
import { sortLists } from "lib/tools/categories"
import Page from "./lists-of/slider"
import { useMainCategories } from "lib/tools/categories"
import { FETCH_MONTH_LIST } from "lib/graphql"
import { useQuery } from "@apollo/react-hooks"
import isBrowser from "lib/tools/is-browser"
import Image from "components/common/image"

const Lists = ({}) => {

	const { c } = useTranslation("common")
	const { lists, lifeLists } = useMainCategories()

	return (
		<section className="has-background">
			<div className="has-background-wrapper">
				<ul className="list content">
					<li>
						<h2 className="section-title icon-chair">{c("lists-of-strong")}</h2>
						<div className="excerpt">
							<p>{c("lists-of-description")}</p>
						</div>
						<Cat categories={lists} />
					</li>
					<li>
						<h2 className="section-title icon-chair">{c("movies-of-their-life-strong")}</h2>
						<div className="excerpt">
							<p>{c("movies-of-their-life-description")}</p>
						</div>
						<Cat categories={lifeLists} />
					</li>
				</ul>
			</div>
			<figure className="background">
				<picture>
					<Image src="/static/images/lists-of-background.jpg" alt={c("lists-of")} />
				</picture>
			</figure>
		</section>
	)
}

const Cat = ({categories}) => {
	const sliderRef = useRef(null)
	const directorLists = []
	const lists = sortLists(categories)
	const [chunk, setChunk] = useState(16)
	const { c } = useTranslation("common")

	var i
	for (i=0; i<lists.length; i+=chunk) {
		directorLists.push(lists.slice(i,i+chunk))
	}

	const manageWidth = () => {
		if (sliderRef.current && isBrowser) {
			const width = window.innerWidth
			if (width < 760) setChunk(8)
			else if (width < 1200) setChunk(12)
			else if (width < 1600) setChunk(16)
			else setChunk(20)
		}
	}

	useEffect(() => {
		manageWidth()
		window.addEventListener('resize', manageWidth)
		return () => {
			window.removeEventListener('resize', manageWidth)
		}
	}, [])

	const { loading, data } = useQuery(FETCH_MONTH_LIST)

	let monthListId
	if (!loading) {
		const monthListCat = data.cms.topMenuWidget.elements.items.find(i => i.targetType == "CATEGORY")
		if (monthListCat) {
			monthListId = monthListCat.target.id
		}
	}

	return (
		<Slider
			slides={directorLists}
			slideComponent={Page}
			slidePropKey="list"
			slideRelatedProps={{
				monthListId
			}}
		/>
	)
}

export default Lists
