import { useLazyQuery } from "@apollo/react-hooks"
import { useEffect, useState } from "react"

import SkeletonLink from "components/common/skeleton-link"
import { FETCH_PRODUCT_CATEGORIES } from "lib/graphql"
import { useTranslation } from "lib/i18n/translation"
import { useProductsFilters } from "lib/products"
import { includedInClicSubscriptionDataLayer } from "lib/gtm/interactions"

export const SubscriptionAvailability = ({ product, loading }) => {
	const { c } = useTranslation("common")
	const [getProductCategories, { data: productCategories }] = useLazyQuery(FETCH_PRODUCT_CATEGORIES, { variables: { id: product.id } })
	const [displayInSubscription, setDisplayInSubscription] = useState(false)
	const { subscriptionCategoriesId } = useProductsFilters()

	useEffect(() => {
		if (subscriptionCategoriesId.length > 0 && !loading) {
			getProductCategories({ variables: { id: product.id }})
		}
	}, [subscriptionCategoriesId, loading])

	useEffect(() => {
		if (productCategories) {
			const { cms: { products: { items: [{ extension: { categories } }] } } } = productCategories
			setDisplayInSubscription(categories.items.some(category => subscriptionCategoriesId.includes(category.id)))
		}

	}, [productCategories])

	if (!displayInSubscription) {
		return null
	}

	return (
		<SkeletonLink href="/subscription" passHref={true}>
			<a
				className={`alert film-event icon-selection-alt link`}
				title={c("svod-in-subscription")}
				onClick={(e) => { includedInClicSubscriptionDataLayer(product.name) }}
			>
				{c("svod-in-subscription")}
			</a>
		</SkeletonLink>
	)
}
